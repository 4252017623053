import React, { useContext, useCallback, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload';
import { Parallax } from 'react-scroll-parallax';
import { ParallaxContext } from 'react-scroll-parallax/cjs/helpers';
import throttle from 'lodash/throttle';
import Head from 'next/head'
import ImageFactory from '../../../adapters/cloudinary/ImageFactory';
import { useIsomorphicLayoutEffect } from '../../../adapters/helpers/Hooks';
import { roundUpTheSize } from '../BackgroundImage/BackgroundImage.helpers'
import { isIE } from '../../../adapters/helpers/Utils';
import { ImageConstants } from '../../../adapters/helpers/Constants';
import { optimizeImage } from '../../../adapters/helpers/pagespeedHelper';

export default function Img(props) {

    const {
        imageId,
        imageFormat,
        imageQuality,
        contentImageId,
        elemId,
        alt,
        transformations,
        customClassName,
        alignmentClassName,
        format,
        noLazyLoad,
        resetImage,
        ariaHidden
    } = props;

    const nodeRef = useRef(null);
    const nodeImg = useRef(null);
    const [width, setWidth] = useState(0);
    let [windowWidth, setWindowWidth] = useState(ImageConstants.defaultWidth);
    const isPreload = props.isPreload;
    const isBannerImage = props.isBannerImage
    let classname= 'ob-mainAsset-wrapper';
    if (alignmentClassName) classname += ` ${alignmentClassName}`;
    if (customClassName) classname += ` ${customClassName}`;

    function updateWidth() {
        if (nodeRef.current) {
            setWidth(roundUpTheSize(nodeRef.current.getBoundingClientRect().width));
        }
    }

    useEffect(
        () => {
            const handleResize = throttle(updateWidth, 100);

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
                handleResize.cancel();
            };
        },
        []
    );

    useEffect(()=>{     
        window.addEventListener('resize', windowResize)
        windowResize()
        return(()=>{
            window.removeEventListener('resize', windowResize)
        })
        
    },[])

    function windowResize() {
        setWindowWidth(window.innerWidth);
      }

    useEffect(
        () => {
            if (resetImage && nodeImg.current) {
                let imgSrc = nodeImg.current.src;
                nodeImg.current.src = '';
                setTimeout(() => {
                    nodeImg.current.src = imgSrc;
                }, 0);
            }
        },
        [ resetImage ]
    );

    useIsomorphicLayoutEffect(
        updateWidth,
        []
    );

    let finalTransformations = `w_${(width !== 0) ? width : 'auto'}${transformations ? ',' + transformations : ''},q_auto,f_${format}`;
    if(isPreload){
        finalTransformations = `w_${windowWidth < ImageConstants.defaultDesktopWidth ? ImageConstants.defaultWidth : (width !== 0) ? width : 'auto'}${transformations ? ',' + transformations : ''},q_auto,f_${format}`
    }

    const preloadImageUrl = contentImageId && optimizeImage(ImageFactory.buildContentfullImageUrl(contentImageId), imageFormat, imageQuality);
    let url = contentImageId && optimizeImage(ImageFactory.buildContentfullImageUrlByWidth(contentImageId,width), imageFormat, imageQuality);

    // We can't call dpr 1.5/2/3 and add srcset because we don't know if the image really exist.
    // If you call an image of width 500px then this image does not exist cloudinary will provide an auto width image
    // The problem: In this case the 2x image will display a width auto image divided by 2, if 3x it will divided the image by 3.
    // We can't force the user to upload 3x 2x images.
    //const srcSet = (width !== 0) ? ImageFactory.dpr(image, finalTransformations) : undefined;

    return (
        <div
            className={`${classname ? classname : ''} ${props.imageClassName ? props.imageClassName : ''}`}
            ref={nodeRef}
            style={{
                marginBottom: props.offsetBottom,
                marginTop: props.offsetTop,
                marginLeft: props.offsetLeft,
                marginRight: props.offsetRight,
                transform: props.scale ? `scale(${props.scale})`: undefined
            }}
        >
            {isPreload  && <Head>
                 <link rel="preload" href={preloadImageUrl} as="image" />
            </Head> }
            {!noLazyLoad && !isPreload ? (
                <LazyLoad offset={500}>
                    {props.hasAssetParallax && !isIE() ? (
                        <ParallaxImg imgRef={nodeImg} 
                            imgClassName='ob-mainAsset-wrapper-img'
                            id={elemId} 
                            src={url} 
                            alt={alt} 
                            x={props.assetX} 
                            ariaHidden={ariaHidden} 
                            y={props.assetY} />
                    ) : (
                        <img ref={nodeImg} 
                            className='ob-mainAsset-wrapper-img'
                            id={elemId} 
                            src={url} 
                            aria-hidden={ariaHidden} 
                            alt={alt || ''} aria-label='banner-image'/>
                    )}
                </LazyLoad>
            ) : props.hasAssetParallax && !isIE() ? (
                <ParallaxImg imgRef={nodeImg} 
                    id={elemId} 
                    src={url} 
                    alt={alt}
                    ariaHidden={ariaHidden} 
                    x={props.assetX} 
                    y={props.assetY} />
            ) : (
                <img ref={nodeImg} 
                    className={isPreload && isBannerImage && 'ob-background-wrapper-visible'}
                    id={elemId} 
                    src={preloadImageUrl} 
                    aria-hidden={ariaHidden} 
                    alt={alt || ''} />
            )}
        </div>
    )
}

Img.defaultProps = {
    transformations: 'c_limit',
    format: 'auto',
    hasAssetParallax: false,
    resetImage: false
};

Img.propTypes = {
    imageId: PropTypes.string,
    contentImageId: PropTypes.string,
    elemId: PropTypes.string,
    alt: PropTypes.string,
    scale: PropTypes.string,
    offsetBottom: PropTypes.string,
    offsetTop: PropTypes.string,
    offsetLeft: PropTypes.string,
    offsetRight: PropTypes.string,
    transformations: PropTypes.string,
    className: PropTypes.string,
    customClassName: PropTypes.string,
    alignmentClassName: PropTypes.string,
    format: PropTypes.string,
    noLazyLoad: PropTypes.bool,
    resetImage: PropTypes.bool,
    hasAssetParallax: PropTypes.bool,
    assetX: PropTypes.array,
    assetY: PropTypes.array,
    ariaHidden: PropTypes.bool,
    imageClassName: PropTypes.string,
    isPreload: PropTypes.bool,
    isBannerImage: PropTypes.bool,
};

function ParallaxImg({ imgRef, x, y, src, alt, imgClassName, id, ariaHidden}) {
    const parallaxController = useContext(ParallaxContext);

    const handleLoad = useCallback(
        () => {
            // updates cached values after image dimensions have loaded
            parallaxController.update();
        },
        [ parallaxController ]
    );

    return (
        <Parallax className='img-parallax' x={x} y={y}>
            <img ref={imgRef} className={imgClassName} id={id} aria-hidden={ariaHidden} src={src} alt={alt || ''} onLoad={handleLoad}/>
        </Parallax>
    );
}

ParallaxImg.propTypes = {
    imgRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.any })
    ]),
    x: PropTypes.array,
    y: PropTypes.array,
    src: PropTypes.string,
    alt: PropTypes.string,
    imgClassName: PropTypes.string,
    id: PropTypes.string,
    ariaHidden: PropTypes.bool
}